/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    h3: "h3",
    span: "span",
    math: "math",
    semantics: "semantics",
    mrow: "mrow",
    mi: "mi",
    mo: "mo",
    mn: "mn",
    mspace: "mspace",
    annotation: "annotation",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "haskellの概要や用語の説明をまとめておく"), "\n", React.createElement(_components.h2, {
    id: "ソースコード",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E3%82%BD%E3%83%BC%E3%82%B9%E3%82%B3%E3%83%BC%E3%83%89",
    "aria-label": "ソースコード permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ソースコード"), "\n", React.createElement(_components.p, null, "iHaskellで試している"), "\n", React.createElement(_components.h3, {
    id: "haskell入門",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#haskell%E5%85%A5%E9%96%80",
    "aria-label": "haskell入門 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Haskell入門"), "\n", React.createElement(_components.h3, {
    id: "ウォークスルー-haskell",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E3%82%A6%E3%82%A9%E3%83%BC%E3%82%AF%E3%82%B9%E3%83%AB%E3%83%BC-haskell",
    "aria-label": "ウォークスルー haskell permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ウォークスルー Haskell"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://walk.northcol.org/haskell/"
  }, "ウォークスルー Haskell - Haskell 入門"), "をiHaskellでやってみた\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">.ipynb</code>"
    }
  }), "に書いておく"), "\n", React.createElement(_components.p, null, "https://github.com/uni-3/haskells"), "\n", React.createElement(_components.h2, {
    id: "用語",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E7%94%A8%E8%AA%9E",
    "aria-label": "用語 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "用語"), "\n", React.createElement(_components.p, null, "気になった語をまとめておく"), "\n", React.createElement(_components.h3, {
    id: "純粋関数型言語",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E7%B4%94%E7%B2%8B%E9%96%A2%E6%95%B0%E5%9E%8B%E8%A8%80%E8%AA%9E",
    "aria-label": "純粋関数型言語 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "純粋関数型言語"), "\n", React.createElement(_components.p, null, "関数でプログラムを構成する言語を指す"), "\n", React.createElement(_components.p, null, "関数とは数学で使う関数と同じで\n同じ引数に対して同じ値を返すもののことをいう"), "\n", React.createElement(_components.div, {
    className: "math math-display"
  }, React.createElement(_components.span, {
    className: "katex-display"
  }, React.createElement(_components.span, {
    className: "katex"
  }, React.createElement(_components.span, {
    className: "katex-mathml"
  }, React.createElement(_components.math, {
    xmlns: "http://www.w3.org/1998/Math/MathML",
    display: "block"
  }, React.createElement(_components.semantics, null, React.createElement(_components.mrow, null, React.createElement(_components.mi, null, "f"), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mi, null, "x"), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")"), React.createElement(_components.mo, null, "="), React.createElement(_components.mn, null, "5"), React.createElement(_components.mi, null, "x"), React.createElement(_components.mspace, {
    linebreak: "newline"
  }), React.createElement(_components.mi, null, "f"), React.createElement(_components.mo, {
    stretchy: "false"
  }, "("), React.createElement(_components.mn, null, "1"), React.createElement(_components.mo, {
    stretchy: "false"
  }, ")"), React.createElement(_components.mo, null, "="), React.createElement(_components.mn, null, "5")), React.createElement(_components.annotation, {
    encoding: "application/x-tex"
  }, "f(x) = 5x \\\\\nf(1) = 5")))), React.createElement(_components.span, {
    className: "katex-html",
    "aria-hidden": "true"
  }, React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.10764em"
    }
  }, "f"), React.createElement(_components.span, {
    className: "mopen"
  }, "("), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "x"), React.createElement(_components.span, {
    className: "mclose"
  }, ")"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2777777777777778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2777777777777778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.64444em",
      verticalAlign: "0em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "5"), React.createElement(_components.span, {
    className: "mord mathnormal"
  }, "x")), React.createElement(_components.span, {
    className: "mspace newline"
  }), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "1em",
      verticalAlign: "-0.25em"
    }
  }), React.createElement(_components.span, {
    className: "mord mathnormal",
    style: {
      marginRight: "0.10764em"
    }
  }, "f"), React.createElement(_components.span, {
    className: "mopen"
  }, "("), React.createElement(_components.span, {
    className: "mord"
  }, "1"), React.createElement(_components.span, {
    className: "mclose"
  }, ")"), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2777777777777778em"
    }
  }), React.createElement(_components.span, {
    className: "mrel"
  }, "="), React.createElement(_components.span, {
    className: "mspace",
    style: {
      marginRight: "0.2777777777777778em"
    }
  })), React.createElement(_components.span, {
    className: "base"
  }, React.createElement(_components.span, {
    className: "strut",
    style: {
      height: "0.64444em",
      verticalAlign: "0em"
    }
  }), React.createElement(_components.span, {
    className: "mord"
  }, "5")))))), "\n", React.createElement(_components.p, null, "のような関数のことを言う"), "\n", React.createElement(_components.p, null, "このように同じ値を渡したとき、必ず同じ結果が返ってくる性質を参照透過性といったりする。関数が状態を持たないことを保証している"), "\n", React.createElement(_components.p, null, "いわゆる変数への副作用がない関数のことを参照透過性を持つというのだろうか"), "\n", React.createElement(_components.p, null, "それらを組み合わせた式で構成されるプログラムのことを純粋関数型プログラミングと言ったりする"), "\n", React.createElement(_components.p, null, "haskellではこのような書き方が推奨されている"), "\n", React.createElement(_components.h3, {
    id: "純粋性",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E7%B4%94%E7%B2%8B%E6%80%A7",
    "aria-label": "純粋性 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "純粋性"), "\n", React.createElement(_components.p, null, "純粋とはすべての変数はイミュータブル（変更不能）であること"), "\n", React.createElement(_components.p, null, "I/O（入出力）を使用したいときはIOモナドと呼ばれる仕組みを使う必要がある。特にI/Oは副作用を伴う場合が多いので、純粋性を保つために使われるらしい"), "\n", React.createElement(_components.h3, {
    id: "遅延評価",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E9%81%85%E5%BB%B6%E8%A9%95%E4%BE%A1",
    "aria-label": "遅延評価 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "遅延評価"), "\n", React.createElement(_components.p, null, "haskellにおいて式は遅延評価される"), "\n", React.createElement(_components.p, null, "他のプログラミング言語(手続き型言語と言ったりする)では関数は呼び出されたときに実行される"), "\n", React.createElement(_components.p, null, "haskellでは必要になるまで実行されない"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">f(1/x)</code>"
    }
  }), "という関数を実行するとき、遅延評価しない場合は、1/xを先に計算し、その結果を引数としてfが呼び出される"), "\n", React.createElement(_components.p, null, "haskellでは1/xは計算されずに、fに渡される。実際に引数が使われるタイミングで計算される。これを遅延評価という"), "\n", React.createElement(_components.p, null, "メリットとしては、使われない式は評価されなくなるなどがある"), "\n", React.createElement(_components.p, null, "デメリットとしては、評価の順番が予測しづらいこと、", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">g(f)</code>"
    }
  }), "のような関数は、", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">g->f</code>"
    }
  }), "の順で実行されるなど直感的でない場合がある。また、未評価の式が溜まっていき、メモリ消費が増えスペースリークという状態になるときがある"), "\n", React.createElement(_components.h3, {
    id: "ioアクション",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#io%E3%82%A2%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3",
    "aria-label": "ioアクション permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "I/Oアクション"), "\n", React.createElement(_components.p, null, "haskellのような関数型言語は式（関数）を組み合わせてプログラムを記述していく"), "\n", React.createElement(_components.p, null, "値を渡して評価するのみだと、何がしかの処理を出力するなど、I/O部分の相互作用はできない\nそこで"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "ファイルの読み書き"), "\n", React.createElement(_components.li, null, "標準入出力"), "\n", React.createElement(_components.li, null, "HTTP通信"), "\n", React.createElement(_components.li, null, "現在時刻の取得"), "\n"), "\n", React.createElement(_components.p, null, "などをおこなう式、I/Oアクションと呼ばれる式を用いる"), "\n", React.createElement(_components.p, null, "例えばputStrLnは値を返さない(Unit型が返り値)。値を返さずに、画面に文字列を表示するという副作用(side-effect)を引き起こしている。"), "\n", React.createElement(_components.p, null, "式の評価以外の何かを起こす式をI/Oアクションという"), "\n", React.createElement(_components.h3, {
    id: "参考",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E5%8F%82%E8%80%83",
    "aria-label": "参考 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "参考"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://walk.northcol.org/haskell/"
  }, "ウォークスルー Haskell - Haskell 入門")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.amazon.co.jp/dp/B075WSB6D8/ref=dp-kindle-redirect?_encoding=UTF8&btkr=1"
  }, "『Haskell入門』")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
